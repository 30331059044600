import { useQuestionizerStore } from "../types/state.ts";
import InputAntragsteller from "./input-antragsteller.tsx";
import InputBaujahr from "./input-baujahr.tsx";
import InputEvu from "./input-evu.tsx";
import InputGebaeudeTyp from "./input-gebaeude-typ.tsx";
import InputGewerk from "./input-gewerk.tsx";
import InputGrundversorger from "./input-grundversorger.tsx";
import InputPlz from "./input-plz.tsx";

import InputGemeinnuetzig from "./input-gemeinnuetzig.tsx";
import InputWohngebaeude from "./input-wohngebaude.tsx";
import InputGewerbeGebaeude from "./input-gewerbegebaeude.tsx";
import InputAntragstellerSelect from "./input-antragsteller-select.tsx";
import InputGebaeudeTyp3 from "./input-gebaeude-typ-3.tsx";
import InputAltbauNeubau from "./input-altbau-neubau.tsx";
import InputForm from "./input-form.tsx";
import InputGroupToggle from "./input-group-toggle.tsx";

export default function QuestionizerForm(){
    const step = useQuestionizerStore((state) => state.step);  
    const config = useQuestionizerStore((state) => state.config);
    //const activeGroups = useQuestionizerStore((state) => state.activeGroups);
    const activeGroup = useQuestionizerStore((state) => state.activeGroup);
    const toggleGroup = useQuestionizerStore((state) => state.toggleGroup);
    //const activeItemsPerGroup = useQuestionizerStore((state) => state.activeItemsPerGroup);

    if (!config){
      return <div>Ein Fehler ist aufgetreten: Keine Konfiguration gefunden.</div>
    }
    
    //const baseurl = (config?.baseurl! + (config?.country ? config?.country! + "/" : "") + (config?.app ? config?.app + "/" : "")) || "";
    const baseurl = config?.baseurl! || "";

    const currentStep = config!.layout[step];

    const inputs = currentStep.sections.map((section,i) => {

      // let cols = "";
      // switch (section.inputs.length){
      //   case 1: cols += "grid-cols-1 sm:grid-cols-1"; break;
      //   case 2: cols += "grid-cols-2 sm:grid-cols-2"; break;
      //   case 3: cols += "grid-cols-2 sm:grid-cols-3"; break;
      //   default: cols += "grid-cols-2 sm:grid-cols-3";
      // }

      //let layout = 'w-fot grid ' + cols;
      let layout = 'section__inputs--grid'
      if (section.type === "column") {
        layout = "w-full flex flex-col";
      }else if (section.type === "row") {
        layout = "w-full flex flex-row";
      }

      //let section_active = section.group !== undefined ? activeGroups[section.group] == true : true;
      const is_group_section = section.groups !== undefined;
      const section_active = is_group_section ? section.groups?.includes(activeGroup ?? 99) : true;
      // let activeItems = 0;
      // if (is_group_section){
      //   activeItems = activeItemsPerGroup[section.group];
      // }

      return (<div id={ is_group_section ? "group-"+section.groups![0] : ""} className={"section" + (section_active ? "" : " section--hidden") + (is_group_section ? " section--group" : "")} key={i}>
        <div className="section__header">
          {section.title && <h2 className="section__title">{section.title}</h2>}
          {section.infoTop && <p className="section__info-top">{section.infoTop}</p>}
        </div>
        <div className={"section__inputs " + layout}>
          {section.type === "grid" && section.inputs.length == 2 ? <div className={"hidden md:block sm:col-span-2"}></div> : <></>}
          {section.inputs.map( (input) => {
          switch (input.type){
            case 'plz':
              return <InputPlz label={input.label} id={input.id} key={input.id}></InputPlz>
            case 'grouptoggle':
              return <InputGroupToggle label={input.label} icon={baseurl + input.icon} icon_active={baseurl + input.icon_active} id={input.id} key={input.id}></InputGroupToggle>
            case 'gewerk':
              return <InputGewerk label={input.label} icon={baseurl + input.icon} icon_active={baseurl + input.icon_active} id={input.id} key={input.id}></InputGewerk>
            case 'altbau_neubau':
                return <InputAltbauNeubau 
				    label_altbau={input.label_altbau}
					label_neubau={input.label_neubau}
					icon_altbau={baseurl + input.icon_altbau} 
					icon_altbau_active={baseurl + input.icon_altbau_active} 
					icon_neubau={baseurl + input.icon_neubau} 
					icon_neubau_active={baseurl + input.icon_neubau_active} 
					id={input.id} 
					key={input.id}></InputAltbauNeubau>
            case 'gebaeude_typ':
              return <InputGebaeudeTyp icon_einfamilienhaus={baseurl + input.icon_einfamilienhaus} icon_einfamilienhaus_active={baseurl + input.icon_einfamilienhaus_active} icon_mehrfamilienhaus={baseurl + input.icon_mehrfamilienhaus} icon_mehrfamilienhaus_active={baseurl + input.icon_mehrfamilienhaus_active} id={input.id} key={input.id}></InputGebaeudeTyp>
            case 'gebaeude_typ_3':
              return <InputGebaeudeTyp3 
                label_einfamilienhaus={input.label_einfamilienhaus}
                label_mehrfamilienhaus={input.label_mehrfamilienhaus}
                label_nichtwohngebaeude={input.label_nichtwohngebaeude}
                icon_einfamilienhaus={baseurl + input.icon_einfamilienhaus} 
                icon_einfamilienhaus_active={baseurl + input.icon_einfamilienhaus_active} 
                icon_mehrfamilienhaus={baseurl + input.icon_mehrfamilienhaus} 
                icon_mehrfamilienhaus_active={baseurl + input.icon_mehrfamilienhaus_active} 
                icon_nichtwohngebaeude={baseurl + input.icon_nichtwohngebaeude} 
                icon_nichtwohngebaeude_active={baseurl + input.icon_nichtwohngebaeude_active} 
                id={input.id}
                key={input.id}></InputGebaeudeTyp3>
            case 'baujahr':
              return <InputBaujahr label={input.label} id={input.id} key={input.id}></InputBaujahr>
            case 'grundversorger':
              return <InputGrundversorger label={input.label} id={input.id} key={input.id}></InputGrundversorger>
            case 'antragsteller':
              return <InputAntragsteller label={input.label} icon={baseurl + input.icon} icon_active={baseurl + input.icon_active} antragsteller_type={input.code} id={input.id} key={input.id}></InputAntragsteller>
            case 'antragsteller-select':
                return <InputAntragstellerSelect label={input.label} id={input.id} key={input.id}></InputAntragstellerSelect>
            case 'evu':
              return <InputEvu label={input.label} id={input.id} key={input.id}></InputEvu>
            case 'gemeinnuetzig':
              return <InputGemeinnuetzig label={input.label} icon={baseurl + input.icon} icon_active={baseurl + input.icon_active} id={input.id} key={input.id} />
            case 'wohngebaeude':
              return <InputWohngebaeude label={input.label} icon={baseurl + input.icon} icon_active={baseurl + input.icon_active} id={input.id} key={input.id}/>
            case 'form':
              return <InputForm id={input.id}  firstname={input.firstname} lastname={input.lastname} email={input.email} tel={input.tel} key={input.id} dataprotection_link={input.dataprotection_link}  dataprotection_link_text={input.dataprotection_link_text} dataprotection_a={input.dataprotection_a} dataprotection_b={input.dataprotection_b}/> 
            case 'gewerbegebaeude':
              return <InputGewerbeGebaeude label={input.label} icon={baseurl + input.icon} icon_active={baseurl + input.icon_active} id={input.id} key={input.id}/>
          }
        })}</div>
        {is_group_section && <div className="step_buttons__button btn step_buttons__next mr-auto ml-auto mt-4" onClick={()=>{if(is_group_section){toggleGroup(section.groups![0]!, false);}}}>
            <span>Weitere Maßnahmen</span><div className=" step_buttons__icon icon-inline--right icon-right--white pl-4 "></div> 
        </div>}
        {section.infoBottom && <p className="section__info-bottom">{section.infoBottom}</p>}
      </div>);
    });
    
    return (<div className="form">
              <h1 className="form__title">{currentStep.title}</h1>
            {inputs}
          </div>);  
  }     