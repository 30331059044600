import { useQuestionizerStore } from "../types/state.ts";
import Toggle from "./toggle.tsx";

export default function InputGewerk (props: {id: number, icon:string, icon_active?:string, label: string}){
  const updateValidation = useQuestionizerStore((state) => state.updateValidation);
  const gewerk = useQuestionizerStore((state) => state.gewerk[props. id]);
  const toggleGewerk = useQuestionizerStore((state) => state.toggleGewerk);

  return <Toggle label={props.label} icon={props.icon} icon_active={props.icon_active} value={gewerk} onChange={(value)=>{
      toggleGewerk(props.id, value);
      updateValidation();
    }}/>
}
