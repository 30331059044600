import { useQuestionizerStore } from "../types/state.ts";

export default function InputGrundversorger (props: {id: number, label: string}){
  //const grundversorger = useQuestionizerStore((state) => state.grundversorger);
  //const setGrundversorger = useQuestionizerStore((state) => state.setGrundversorger);
  const updateValidation = useQuestionizerStore((state) => state.updateValidation);


  return <div>
    <label>{props.label}</label>
    <input type="text" value={""} onChange={()=>{
      updateValidation();
    }}/>
  </div>
}