import { useQuestionizerStore } from "../types/state.ts";
import Toggle from "./toggle.tsx";

export default function InputGebaeudeTyp3 (props: {
  id: number, 
  label_einfamilienhaus?: string, 
  icon_einfamilienhaus: string, 
  icon_einfamilienhaus_active?: string, 
  label_mehrfamilienhaus?: string, 
  icon_mehrfamilienhaus: string, 
  icon_mehrfamilienhaus_active?: string,
  label_nichtwohngebaeude?: string,
  icon_nichtwohngebaeude: string,
  icon_nichtwohngebaeude_active?: string,
}){
  const updateValidation = useQuestionizerStore((state) => state.updateValidation);
  const gebaeude = useQuestionizerStore((state) => state.gebaeude);
  const setGebaeude = useQuestionizerStore((state) => state.setGebaeude);
  //const wohngebaeude = useQuestionizerStore((state) => state.wohngebaeude);
  const setWohngebaeude = useQuestionizerStore((state) => state.setWohngebaeude);
  //const error_default = useQuestionizerStore((state) => state.translation.errors['input-gebaeudetyp--default']);
  const label_einfamilienhaus = props.label_einfamilienhaus || useQuestionizerStore((state) => state.translation.labels['input-gebaeudetyp--einfamilienhaus']);
  const label_mehrfamilienhaus = props.label_mehrfamilienhaus || useQuestionizerStore((state) => state.translation.labels['input-gebaeudetyp--mehrfamilienhaus']);
  const label_nichtwohngebaeude = props.label_nichtwohngebaeude || useQuestionizerStore((state) => state.translation.labels['input-gebaeudetyp--nichtwohngebaeude']);

  return <div className="section__inputs section__inputs--grid mb-8">
        <Toggle label={label_einfamilienhaus} icon={props.icon_einfamilienhaus} icon_active={props.icon_einfamilienhaus_active} value={gebaeude == "Einfamilienhaus"} onChange={(_)=>{
          setGebaeude("Einfamilienhaus");  
          setWohngebaeude(true);
          updateValidation();     
        }}/>
        <Toggle label={label_mehrfamilienhaus} icon={props.icon_mehrfamilienhaus} icon_active={props.icon_mehrfamilienhaus_active} value={gebaeude == "Mehrfamilienhaus"} onChange={(_)=>{
          setGebaeude("Mehrfamilienhaus");   
          setWohngebaeude(true);
          updateValidation();    
        }}/>
         <Toggle label={label_nichtwohngebaeude} icon={props.icon_nichtwohngebaeude} icon_active={props.icon_nichtwohngebaeude_active} value={gebaeude == ""} onChange={(_)=>{
          setGebaeude("");
          setWohngebaeude(false);
          updateValidation();
        }}/>
    </div>
}