import { useQuestionizerStore } from "../types/state.ts";
import Toggle from "./toggle.tsx";

export default function InputGemeinnuetzig (props: {id: number, label: string, icon: string, icon_active?:string}){
  const gemeinnuetzig = useQuestionizerStore((state) => state.gemeinnuetzig);
  const setGemeinnuetzig = useQuestionizerStore((state) => state.setGemeinnuetzig);
  const updateValidation = useQuestionizerStore((state) => state.updateValidation);

  const label = useQuestionizerStore((state) => state.translation.errors['input-gemeinnuetzig']);

  return <div>
    <div className="flex flex-row justify-center">
        <Toggle label={props.label || label} icon={props.icon} icon_active={props.icon_active} value={gemeinnuetzig || false} onChange={(_)=>{
          setGemeinnuetzig(!gemeinnuetzig);
          updateValidation();
        }}/>
    </div>
  </div>
}