import { useQuestionizerStore } from "../types/state.ts";
import Toggle from "./toggle.tsx";

export default function InputGroupToggle (props: {id: number, icon:string, icon_active?:string, label: string}){
  const updateValidation = useQuestionizerStore((state) => state.updateValidation);
  const activeGroup = useQuestionizerStore((state) => state.activeGroup);
  const toggleGroup = useQuestionizerStore((state) => state.toggleGroup);
  const activeItemsPerGroup = useQuestionizerStore((state) => state.activeItemsPerGroup);

  return <Toggle count={activeItemsPerGroup ? activeItemsPerGroup[props.id] : undefined} label={props.label} icon={props.icon} icon_active={props.icon_active} value={props.id == activeGroup} onChange={(value)=>{
      toggleGroup(props.id, value);
      updateValidation();
      document.getElementById("group-"+props.id)?.scrollIntoView({ block: 'start',  behavior: 'smooth' });
    }}/>
}
