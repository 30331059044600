import { useQuestionizerStore } from "../types/state.ts";

export default function Toggle (props: {value: boolean, icon:string, icon_active?:string, onChange: (value: boolean) => void,  label: string, count?: number}){
  const useLabels = useQuestionizerStore((state) => state.config?.useLabels);
  const show_count = (props.count !== undefined && props.count > 0);
  const icon_url = (props.value || props.icon_active === undefined) ? `url(${props.icon_active})` : `url(${props.icon})`;
  
  return <div className={"toggle"} onClick={()=>{props.onChange(!props.value)}}>
          {show_count && 
            <div className="toggle-bubble">
              {props.count}
            </div>
          }
          <div className={"toggle__img " + (props.value ? "toggle__img--active " : "") + (show_count ? "toggle__img--has-count " : "")} style={{backgroundImage: icon_url}}></div>
          {useLabels && <label className='toggle__label'>{props.label}</label>}
        </div>
}

