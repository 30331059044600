import { useQuestionizerStore } from "../types/state.ts";
import Toggle from "./toggle.tsx";

export default function InputAltbauNeubau (props: {
	id: number, 
	label_altbau?: string,
	label_neubau?: string,
	icon_altbau: string, 
	icon_altbau_active?: string, 
	icon_neubau: string, 
	icon_neubau_active?: string
}){
  const typ = useQuestionizerStore((state) => state.typ);
  const setTyp = useQuestionizerStore((state) => state.setTyp);
  const updateValidation = useQuestionizerStore((state) => state.updateValidation);

  const label_altbau =  props.label_altbau || useQuestionizerStore((state) => state.translation.errors['input-altbau-neubau--altbau']);
  const label_neubau =  props.label_neubau || useQuestionizerStore((state) => state.translation.errors['input-altbau-neubau--neubau']);

  return <div className="section-altbau-neubau section__inputs section__inputs--grid mb-8">
        <div className="hidden md:block sm:col-span-2"></div>
        <Toggle label={label_altbau} icon={props.icon_altbau} icon_active={props.icon_altbau_active} value={typ == "Altbau"} onChange={(_)=>{
          setTyp("Altbau");
          updateValidation();   
        }}/>
        <Toggle label={label_neubau} icon={props.icon_neubau} icon_active={props.icon_neubau_active} value={typ == "Neubau"} onChange={(_)=>{
          setTyp("Neubau");       
          updateValidation();
        }}/>
    </div>
}