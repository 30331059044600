import { useQuestionizerStore } from "../types/state";
import Input from "./input";
import Checkbox from "./checkbox.tsx";

export default function InputForm (props: {id: number, firstname?: "required" | "optional", lastname?: "required" | "optional", email?: "required" | "optional", tel?: "required" | "optional", dataprotection_link? : string, dataprotection_link_text? : string, dataprotection_a? : string, dataprotection_b? : string}){
  const firstname = useQuestionizerStore((state) => state.firstname);
  const setFirstname = useQuestionizerStore((state) => state.setFirstname);
  const lastname = useQuestionizerStore((state) => state.lastname);
  const setLastname = useQuestionizerStore((state) => state.setLastname);
  const email = useQuestionizerStore((state) => state.email);
  const setEmail = useQuestionizerStore((state) => state.setEmail);
  const dataprotection = useQuestionizerStore((state) => state.dataprotection);

  const setDataprotection = useQuestionizerStore((state) => state.setDataprotection);

  const tel = useQuestionizerStore((state) => state.tel);
  const setTel = useQuestionizerStore((state) => state.setTel);
  const updateValidation = useQuestionizerStore((state) => state.updateValidation);
  const label_firstname = useQuestionizerStore((state) => state.translation.labels['input-form-firstname']);
  const label_lastname = useQuestionizerStore((state) => state.translation.labels['input-form-lastname']);
  const label_email = useQuestionizerStore((state) => state.translation.labels['input-form-email']);
  const label_tel = useQuestionizerStore((state) => state.translation.labels['input-form-tel']);

  
  return <div className="col-span-12">
       {
        props.firstname !== undefined &&
        <Input<string> label={(label_firstname) + (props.firstname === "required" ? " *" : '')} value={firstname} onChange={(value)=>{
          setFirstname(value);
          updateValidation();
        }} type="text"/>
      }
      <br></br>
      {
        props.lastname !== undefined &&
        <Input<string> label={(label_lastname) + (props.lastname === "required" ? " *" : '')} value={lastname} onChange={(value)=>{
          setLastname(value);
          updateValidation();
        }} type="text"/>
      }
      <br></br>
      {
        props.email !== undefined &&
        <Input<string> label={(label_email) + (props.email === "required" ? " *" : '')} value={email} onChange={(value)=>{
          setEmail(value);
          updateValidation();
        }} type="text"/>
      }
      <br></br>
      {
      props.tel !== undefined &&
        <Input<string> label={(label_tel) + (props.tel === "required" ? " *" : '')} value={tel} onChange={(value)=>{
          setTel(value);
          updateValidation();
        }} type="text"/>
      }
      <br></br>
      {
        props.dataprotection_a && props.dataprotection_link &&
        <>
          <span>{props.dataprotection_a} </span>
          <a href={props.dataprotection_link} target="_blank">{props.dataprotection_link_text ? props.dataprotection_link_text : props.dataprotection_link}</a>
        </>
       
      }
      {
        props.dataprotection_b &&
        <div className="flex flex-row mt-4"> 
          <Checkbox value={dataprotection == true} onChange={(value)=>{
            setDataprotection(value);
            updateValidation();
          }}/>
          <p className="mt-[0.5rem]">{props.dataprotection_b}</p>
        </div>
       
      }
  </div>
}
