import { useEffect } from "react";
import { useQuestionizerStore } from "../types/state";
import StepButtons from "./step-buttons";
import StepNavigation from "./step-navigation";

import LoadingIndicator from "./loading-indicator.tsx";
import QuestionizerResult from "./questionizer-result.tsx";
import QuestionizerForm from "./questionizer-form.tsx";

export default function Questionizer(){
  const step = useQuestionizerStore((state) => state.step);
  const stepCount = useQuestionizerStore((state) => state.stepsCount());

  const config = useQuestionizerStore((state) => state.config);
  const session_id = useQuestionizerStore((state) => state.session_id);
  const session_id_timestamp = useQuestionizerStore((state) => state.session_id_timestamp);
  const requestSessionId = useQuestionizerStore((state) => state.requestSessionId);
  const pdfLink = useQuestionizerStore((state) => state.pdfLink);

  useEffect(() => {
    if (config?.usePdfDownload && pdfLink){
      window.open(pdfLink!);
    }
  }, [pdfLink])

   useEffect(() => {
    if(session_id === undefined || session_id_timestamp === undefined || (session_id_timestamp + 43200000) < Date.now()){
        requestSessionId();
    }
  }, [config]);
  
  if (!config || !session_id){
    return <LoadingIndicator />
  }

  if (step == stepCount){
    // if(config.usePdfDownload && pdfLink){
    //   return (
    //     <div>
    //         <StepNavigation />
    //         <QuestionizerResult />
    //         <LoadingIndicator></LoadingIndicator>
    //         <StepButtons />
    //     </div>);
    // }else{
      return (
      <div>
          <StepNavigation />
          <QuestionizerResult />
          <StepButtons />
          {config.infoBottomResult && <p className="form__info-bottom">{config.infoBottomResult}</p>}
      </div>);
    // }
  }else{
    return (
        <div>
            {config.infoTop && <p className="form__info-top">{config.infoTop}</p>}
            <StepNavigation />
            <QuestionizerForm />
            <StepButtons />
            {config.infoBottom && <p className="form__info-bottom">{config.infoBottom}</p>}
        </div>
    ); 
  }
}
