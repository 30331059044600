import { useEffect, useState } from "react";
import { useQuestionizerStore } from "../types/state.ts";
import { FDWAntragsteller, FDWResultEntry } from "../../../restfd2/fdw_result.ts";
import Checkbox from "./checkbox.tsx";
import LoadingIndicator from "./loading-indicator.tsx";

export default function QuestionizerResult(){
    const result = useQuestionizerStore((state) => state.result);
    const session_id = useQuestionizerStore((state) => state.session_id);
    const gemeinde_id = useQuestionizerStore((state) => state.gemeinde_id);
    const gewerk = useQuestionizerStore((state) => state.gewerk);
    const gebaeude = useQuestionizerStore((state) => state.gebaeude);
    const typ = useQuestionizerStore((state) => state.typ);
    const baujahr = useQuestionizerStore((state) => state.baujahr);
    const wohngebaeude = useQuestionizerStore((state) => state.wohngebaeude);
    const gewerbegebaude = useQuestionizerStore((state) => state.gewerbegebaeude);
    const gemeinnuetzig = useQuestionizerStore((state) => state.gemeinnuetzig);
    const requestResult = useQuestionizerStore((state) => state.requestResult);
    const antragsteller = useQuestionizerStore((state) => state.antragsteller);
    const antragstellerDetail = useQuestionizerStore((state) => state.antragstellerDetail);

    useEffect(()=>{
      requestResult();
   },[session_id, gebaeude, typ, baujahr, wohngebaeude, gewerbegebaude, gemeinnuetzig, gemeinde_id, gewerk, antragstellerDetail, antragsteller]);

    if (session_id == undefined || result == undefined){
      return <LoadingIndicator />
    }

    return (<div>
        <h1>{"Ergebnisse"}</h1>
        <QuestionizerResultSummary />
        <QuestionizerResultTabs />
    </div>);  
  }

  function QuestionizerResultSummary(){
    const plz = useQuestionizerStore((state) => state.plz);
    const gemeinde = useQuestionizerStore((state) => state.gemeinde);
    const gewerk_labels = useQuestionizerStore((state) => state.getGewerkLabels());
    const gebaeude = useQuestionizerStore((state) => state.gebaeude);
    const typ = useQuestionizerStore((state) => state.typ);
    const baujahr = useQuestionizerStore((state) => state.baujahr);
    const antragsteller = useQuestionizerStore((state) => state.antragsteller);
    const antragstellerDetail = useQuestionizerStore((state) => state.antragstellerDetail);
    const _antragsteller = (antragsteller?.length == 1) ? antragsteller[0] : antragstellerDetail;

    console.log("LABELS:",gewerk_labels);
    
    return (<div className="mb-8">
        <p className="mb-8">Die Programminfos zu den ausgewählten Förderungen können direkt online eingesehen
oder nach Auswahl gespeichert und ausgedruckt werden.</p>
        <section className="summary">
          <h2 className="summary__title">Ihre Angaben</h2>
          <div><span className="summary__bullet">Gewählte Maßnahmen</span>: <span>{gewerk_labels.join(', ')}</span></div>
          <div><span className="summary__bullet">Antragsteller</span>: <span>{FDWAntragsteller[_antragsteller!]}</span></div>
          {/* <div><span className="summary__bullet">PLZ</span>: <span>{plz}</span></div> */}
          <div><span className="summary__bullet">Ort</span>: <span>{plz} {gemeinde?.gemeinde_name}</span></div>
          <div><span className="summary__bullet">Gebäude</span>: <span>{gebaeude}</span></div>
          <div><span className="summary__bullet">Typ</span>: <span>{typ}</span></div>
          <div><span className="summary__bullet">Baujahr</span>: <span>{baujahr}</span></div>
        </section>
    </div>);  
  }

  function QuestionizerResultTabs(){
    const [active_tab, set_active_tab] = useState<"zuschusse" | "darlehen">("zuschusse");
    const result = useQuestionizerStore((state) => state.result);
    type SortedResultsList = Record<string,{region: string, list: Array<FDWResultEntry>}>;

    if (result == undefined){
      return <div></div>
    }

    
    const zuschuesse = result.filter((entry) => entry.fp_type == "Zuschuss").reduce((acc: SortedResultsList, entry : FDWResultEntry)=>{
      if (!acc[entry.region]){
        acc[entry.region] = {region:entry.region, list: [entry]}; 
      }else{
        acc[entry.region].list.push(entry);
      }
      return acc;
    },{});

    const darlehen = result.filter((entry) => entry.fp_type == "Darlehen").reduce((acc : SortedResultsList, entry : FDWResultEntry)=>{
      if (!acc[entry.region]){
        acc[entry.region] = {region:entry.region, list: [entry]}; 
      }else{
        acc[entry.region].list.push(entry);
      }
      return acc;
    },{});

    let zuschuesse_length = Object.keys(zuschuesse).flatMap(key => zuschuesse[key].list).length;
    let darlehen_length = Object.keys(darlehen).flatMap(key => darlehen[key].list).length;

    return (<div className="tabs">
          <div className="tabs__header">
            <h2 className={"tabs__header_item " + (zuschuesse_length == 0 ? " tabs__header_item--empty ": "") + (active_tab == "zuschusse" ? "tabs__header_item--active" : "")} onClick={()=>{set_active_tab("zuschusse")}}>Zuschüsse ({zuschuesse_length})</h2>
            <h2 className={"tabs__header_item " + (darlehen_length == 0 ? " tabs__header_item--empty ": "") + (active_tab == "darlehen" ? "tabs__header_item--active" : "")} onClick={()=>{set_active_tab("darlehen")}}>Darlehen ({darlehen_length})</h2>
          </div>
          <div className="tabs__body">
            <QuestionizerAccordion active={active_tab == "zuschusse"}  regions={zuschuesse} />
            <QuestionizerAccordion active={active_tab == "darlehen"} regions={darlehen} />
          </div>
    </div>);  
  }

  function QuestionizerAccordion(props: {active: boolean, regions: Record<string,{region: string, list: Array<FDWResultEntry>}>}){
    const [active_element, set_active_element] = useState<string>(Object.keys(props.regions)[0]);

    return (
        <div className="accordion">
          {props.active && Object.keys(props.regions).map((key : string)=>{
            const region = props.regions[key];
            return (<div className="accordion__element" key={key}>
              <h3 className="accordion__element_title" onClick={()=>{set_active_element(key)}}><div className={"icon accordion__element_icon " + (active_element == key ? "accordion__element_icon--active" : "")}/><span>{region.region} ({region.list.length})</span></h3>
              {active_element == key && region.list.map((entry : FDWResultEntry)=>
              <QuestionizerResultItem key={entry.fp_id} entry={entry} />
              )}
            </div>);
          })}
        </div>);  
  }

  function QuestionizerResultItem(props:{entry:FDWResultEntry}){
    const include_in_print = useQuestionizerStore((state) => state.include_in_print);
    const setIncludeInPrint = useQuestionizerStore((state) => state.setIncludeInPrint);
    //const setIncludeInPrintExclusive = useQuestionizerStore((state) => state.setIncludeInPrintExclusive);

    const openPrintView = useQuestionizerStore((state) => state.openPrintView);

    return (<div className="result-item flex flex-row items-start mb-4 cursor-pointer">
        <Checkbox value={include_in_print[props.entry.fp_id]} onChange={(value)=>{setIncludeInPrint(props.entry.fp_id, value)}}/>
        <div className="mr-4 flex flex-col items-start">
          <span className="result-item__name font-bold">{props.entry.fp_name}</span>
          <span className="result-item__betrag">{props.entry.fp_betrag}</span>
        </div>
        <span className={"result-item__print"} onClick={(_)=>{
            //setIncludeInPrintExclusive(props.entry.fp_id);
            openPrintView(props.entry.fp_id);
          }}><span className="result-item__print_label">Anzeigen</span><div className={"icon icon-print result-item__icon_print"}></div></span>
    </div>);  
  }
