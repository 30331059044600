import { useQuestionizerStore } from "../types/state.ts";
import Toggle from "./toggle.tsx";

export default function InputWohngebaeude (props: {id: number, label: string, icon: string, icon_active?:string}){
  const wohngebaeude = useQuestionizerStore((state) => state.wohngebaeude);
  const setWohngebaeude = useQuestionizerStore((state) => state.setWohngebaeude);
  const updateValidation = useQuestionizerStore((state) => state.updateValidation);
  const label = useQuestionizerStore((state) => state.translation.errors['input-wohngebaeude']);


  return <div>
    <div className="flex flex-row justify-center">
        <Toggle label={props.label || label} icon={props.icon} icon_active={props.icon_active} value={wohngebaeude || false} onChange={(_)=>{
          setWohngebaeude(!wohngebaeude);
          updateValidation();
        }}/>
    </div>
  </div>
}