import { FDWAntragsteller } from "../../../restfd2/fdw_result.ts";
import { useQuestionizerStore } from "../types/state.ts";
import Select from "./select.tsx";

// export default function InputAntragsteller (props: {id: number, label: string}){
//   const antragsteller = useQuestionizerStore((state) => state.antragsteller);
//   const setAntragsteller = useQuestionizerStore((state) => state.setAntragsteller);
//   const setValidation = useQuestionizerStore((state) => state.setValidation);
  
//   return <div>
//     <label>{props.label}</label>
//     <input type="text" value={""} onChange={()=>{
//       setValidation(props.id, {message:"", valid: true});
//     }}/>
//   </div>
// }

export default function InputAntragstellerSelect (props: {id: number, label: string}){
  const antragsteller = useQuestionizerStore((state) => state.antragsteller);
  const antragstellerDetail = useQuestionizerStore((state) => state.antragstellerDetail);
  const setAntragstellerDetail = useQuestionizerStore((state) => state.setAntragstellerDetail);
  const updateValidation = useQuestionizerStore((state) => state.updateValidation);

  const label = useQuestionizerStore((state) => state.translation.labels['input-antragsteller-select']);

 if (antragsteller === undefined || antragsteller.length < 2){return <div></div>}
  return <div className="input-antragsteller-select">
    <label className={"input-antragsteller-select__label"}>{label}</label>
    <Select<number> label={props.label} value={antragstellerDetail || -1} onChange={(value) => {
         setAntragstellerDetail(value);
         updateValidation();
        }}>
        {antragstellerDetail === undefined && <option key={"default"} value={-1}>{props.label}</option>}
        {antragsteller.map((antragsteller)=><option value={antragsteller} key={antragsteller}>{FDWAntragsteller[antragsteller]}</option>)}
      </Select>
  </div>
}
