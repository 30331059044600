import { useQuestionizerStore } from "../types/state.ts";
import Input from "./input.tsx";
//import { FDWGid } from "../../../restfd2/fdw_gid.ts";
//import {FDWEvuid} from "../../../restfd2/fdw_evuid.ts";
import Select from "./select.tsx";

export default function InputPlz (props: {id: number, label: string}){
  //const requestGemeindeId = useQuestionizerStore((state) => state.requestGemeindeId);
  const config = useQuestionizerStore((state) => state.config);
  const plz = useQuestionizerStore((state) => state.plz);
  const setPlz = useQuestionizerStore((state) => state.setPlz);
  const gemeinde_liste = useQuestionizerStore((state) => state.gemeinde_liste);
  //const setGemeindeListe = useQuestionizerStore((state) => state.setGemeindeListe);
  const gemeinde_id = useQuestionizerStore((state) => state.gemeinde_id);
  const setGemeindeId = useQuestionizerStore((state) => state.setGemeindeId);
  const gemeinde = useQuestionizerStore((state) => state.gemeinde);
  const setGemeinde = useQuestionizerStore((state) => state.setGemeinde);
  //const session_id = useQuestionizerStore((state) => state.session_id);
  const updateValidation = useQuestionizerStore((state) => state.updateValidation);
  //const error_default = useQuestionizerStore((state) => state.translation.errors['input-plz--default']);
  const label_input = useQuestionizerStore((state) => state.translation.labels['input-plz--input']);
  const label_select = useQuestionizerStore((state) => state.translation.labels['input-plz--select']);

  //const setEvu_ids = useQuestionizerStore((state) => state.setEvu_ids);
  //const setEvu1_id = useQuestionizerStore((state) => state.setEvu1_id);

  // function validation(){
  //   // if (((props.allowed.includes("de") && value.match(/\d{5}/)) || (props.allowed.includes("at") && value.match(/\d{4}/))) && gemeinde_id !== undefined){
  //   //   setValidation(props.id, {message:"", valid: true});
  //   // }else{
  //   //   setValidation(props.id, {message:error_default, valid: false});
  //   // }

  //   if (gemeinde_id !== undefined){
  //     setValidation(props.id, {message:"", valid: true});
  //   }else{
  //     setValidation(props.id, {message:error_default, valid: false});
  //   }
  // }

//   useEffect(()=>{
//     validation();
//  },[gemeinde_id, hydationSeed]);

  return <div className="input-container col-span-12">
    <Input<string> label={props.label || label_input} value={plz} onChange={(value)=>{
      let processed_plz = value.replace(/\D+/,"");
      if(config?.country == "at"){
        processed_plz = processed_plz.slice(0,4);
      }else{
        processed_plz = processed_plz.slice(0,5);
      }
      setPlz(processed_plz);
    }} type="text"/>
    {gemeinde && !gemeinde_liste && <p className="input_plz__gemein_name pointer-events-none"><span className="invisible">{plz + "   "}</span><span>{"("+gemeinde.gemeinde_name+")"}</span></p>}
    {(gemeinde_liste && gemeinde_liste.length > 1) && 
      <Select<number> label={label_select} value={gemeinde_id} onChange={(value) => {
         setGemeindeId(value);
         setGemeinde(gemeinde_liste.find( g => g.gemeinde_id == value)!);
         updateValidation();
        }}>
        {gemeinde_id === undefined && <option selected={gemeinde_id == undefined} value={0}>{label_select}</option>}
        {gemeinde_liste.map((gemeinde)=><option value={gemeinde.gemeinde_id}>{gemeinde.gemeinde_name}</option>)}
      </Select>
    }
  </div>
}